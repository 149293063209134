import isInViewport from "./isInViewport";

function animateValue(id:string, start:number, end:number, duration:number) {
    
    let obj = document.getElementById(id);
    var range = end - start;
    var current = start;

    var increment = end > start? 1 : -1;
    var stepTime = Math.abs(Math.floor(duration / range));
    var timer = setInterval(function() {
        current += increment;
      
        if(obj != null) {
            obj.textContent = current.toString();
        }
        if (current == end) {
            clearInterval(timer);
        }
    }, stepTime);
}

const countReset = (el) => {
    var counters = el.querySelectorAll('.count-number');
    for (let i = 0; i < counters.length; i++) {
    
        const number = counters[i];
        const start = parseInt(number.getAttribute('data-start')!);
        number.innerText = start;
    }
}

const countCheck = () => {
    const SECTION_DONATE = document.querySelector('.live-donate');
    const NUMBERS = document.querySelectorAll('.count-number');
    for (let i = 0; i < NUMBERS.length; i++) {
    
        var number = NUMBERS[i];
        var start = parseInt(number.getAttribute('data-start')!);
       var end = parseInt(number.getAttribute('data-end')!);
        
        if(isInViewport(SECTION_DONATE)) {
            if(SECTION_DONATE?.classList.contains('counted') == false && end !== 0) {
                animateValue(number.id,start,end,2000);
                SECTION_DONATE?.classList.add('counted');
            }
            else {
                number.innerText = end;
            }
        }
        
        // if(!isInViewport(SECTION_DONATE)) {
        //     countReset(SECTION_DONATE)
        //     SECTION_DONATE?.classList.remove('counted');
        // }
    }
}
window.addEventListener('scroll',countCheck);




