import Swiper, { Navigation,Pagination,EffectFade } from 'swiper';
import formValidateCheck from './form';
import html2canvas from 'html2canvas';


// slider initialtion
Swiper.use([Navigation,Pagination,EffectFade]);
  
  	const swiper = new Swiper(".swiper-slider", {
    autoHeight:true,
    slidesPerView: 1,
    effect:'fade',
    fadeEffect: {
        crossFade: true
    },
    allowSlideNext:false,
    allowSlidePrev:false,
	allowTouchMove:false,

});



const swiper2 = new Swiper(".postcard-front-slider", {
	autoHeight:true,
	slidesPerView: 1,
	effect:'fade',
	fadeEffect: {
		crossFade: true
	},
	allowSlideNext:true,
	allowSlidePrev:true,
	allowTouchMove:false,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
		type: 'bullets',
	},
	breakpoints: {
        
        768: {
			slidesPerView: 1,
			allowTouchMove:true,
        },
		320: {
			slidesPerView: 1,
			allowTouchMove:true,
        },
	}
});


// main slider -> first slide button
const forwardBtn = document.getElementById('forward') as HTMLButtonElement;

forwardBtn?.addEventListener('click', () => {
	swiper.allowSlideNext = true;
	swiper.slideNext();
})

window.addEventListener('load', () => {
	forwardBtn.disabled = true;
})

// take img from the active slide to the postdard preview ffrontside
const toBacksideBtn = document.querySelector('.postcard-front-slider .to-backside');
const frontCardimg = <HTMLImageElement>document.querySelector('.postcard-slider .card-front-overview img');

toBacksideBtn?.addEventListener('click', () => {
	swiper2.update();
	const activeSlide = <HTMLImageElement>toBacksideBtn.closest('.postcard-slider-navigation')?.previousElementSibling?.querySelector('.swiper-slide-active');
	const img = activeSlide.querySelector('img') as HTMLImageElement;
	frontCardimg.src = img?.src;
	swiper.allowSlideNext = true;
	swiper.slideNext();
})


// form-validation Desktop
const cardPreviewBtn = document.getElementById('postcard-preview');
const requiredInput = document.querySelector('.backside')?.querySelectorAll(".form-control");
const cardPreviewBackInputs = document.querySelector('.card-preview-back')?.querySelectorAll(".form-control");
cardPreviewBtn?.addEventListener('click',(e) => {
	if(formValidateCheck() == 'notValid') {
			e.preventDefault();
			requiredInput?.forEach(input => {
				if(input.value == "") {
					input.classList.add('error');
				}
				else {
					input.classList.remove('error');
				}
			})
	}
	else {
		requiredInput?.forEach(input => {

			for (let i = 0; i < cardPreviewBackInputs!.length; i++) {

				const cardPreviewBackInput = cardPreviewBackInputs![i];
				if(input.name == cardPreviewBackInput.name) {
					console.log(cardPreviewBackInput)
					cardPreviewBackInput.value = input.value;
				}
			}
		})
		swiper.allowSlideNext = true;
		swiper.slideNext();
	}
})


// create a new slide with scale user image src
function showPreview(btn,src){
		
	const wrapper = btn.closest('.postcard-slider-navigation').previousElementSibling as HTMLDivElement;

	wrapper.insertAdjacentHTML('beforeend',`<div class="swiper-slide user-image"><div class="postcard-slider"><div class="postcard"><img src="${src}" alt="" class="img-fluid"></div></div></div>`);
	swiper2.update();
	getLastSlide();
}

// get last slide 
var lastSlide:number;
function getLastSlide() {
	const postcardFrontSlides = document.querySelectorAll('.postcard-front-slider .swiper-slide');
	for (let i = 0; i < postcardFrontSlides.length; i++) {
		if (i === postcardFrontSlides.length - 1) {
			lastSlide = i;
		}
	}
}


// scale user
const uploadBtns = document.querySelectorAll('input[type="file"]');
uploadBtns.forEach(uploadBtn => {
		uploadBtn?.addEventListener('change', (e) => {
			
		const canvas = document.getElementById('canvas')
			canvas!.innerHTML = ''
		if (e.target.files[0]) {
			var reader = new FileReader()
			console.log(reader);
			reader.addEventListener('load',(e) => {
				canvas!.innerHTML = ''
				console.log(e);
				var img = document.createElement('img');
				img.src = e.target?.result;

				canvas!.appendChild(img);
				// window.scrollTo(0, 0)
				html2canvas(canvas!, {
					scale: 1,
					width: 1819,
					height: 1311,
				}).then((canvas) => {
					let finaleImageURL = canvas.toDataURL('image/png')
					
					showPreview(uploadBtn,finaleImageURL);
					swiper2.update();
					swiper2.slideTo(lastSlide);
					
					})
				} ) 

			reader.readAsDataURL(e.target.files[0])
		}
	});
})

// flip-card

const flipBtn = document.querySelector('.flip-btn');
const card = document.querySelector('.cards');

flipBtn?.addEventListener( 'click', function() {
  	card?.classList.toggle('is-flipped');
});

const prevBtn = document.querySelectorAll('.prev');

prevBtn.forEach(btn => {
	btn.addEventListener('click', () => {

		swiper.allowSlidePrev = true;
		swiper.slidePrev();
	})
})

const sendCardBtn = document.getElementById('postcard-send');

sendCardBtn?.addEventListener('click', () => {
	swiper.allowSlideNext = true;
	swiper.slideNext();
})
