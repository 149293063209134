import ResponsiveAutoHeight from 'responsive-auto-height';

// card body same height
import isInViewport from './isInViewport';

const autoHeight = document.querySelector('.card .js-auto-height');

if(autoHeight) {
    new ResponsiveAutoHeight('.card .js-auto-height');
}

window.addEventListener('resize',() => {
    if(autoHeight) {
        new ResponsiveAutoHeight('.card .js-auto-height');
    }
})

const scrollToTopButton = document.getElementById('js-top');

const scrollFunc = () => {

  	let y = window.scrollY;

};

window.addEventListener("scroll", scrollFunc);

const scrollToTop = () => {
 
	const c = document.documentElement.scrollTop || document.body.scrollTop;

	if (c > 0) {
		// window.requestAnimationFrame(scrollToTop);
		window.scrollTo(0, 100);
	}
};

scrollToTopButton?.addEventListener('click', (e) => {
    e.preventDefault();
    scrollToTop();
})





