import isInViewport from "./isInViewport";
const SECTION_DONATE = document.querySelector('.live-donate');
const DONATE_PROGRESS = <HTMLElement>document.querySelector('.donate-progress');

const setDONATE_PROGRESS = (val) => {
    DONATE_PROGRESS.style.width = val + '%';
}

setDONATE_PROGRESS(DONATE_PROGRESS.getAttribute('data-width') as string);

window.addEventListener('scroll',() => {
    if(isInViewport(SECTION_DONATE)) {
        // DONATE_PROGRESSVal(targetShareVal);
        setDONATE_PROGRESS(DONATE_PROGRESS.getAttribute('data-width'));
    }
    else {
        setDONATE_PROGRESS(0);
    }
})