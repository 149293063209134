import { Offcanvas } from 'bootstrap'

/////////// Form floating label /////////////

const form = document.querySelectorAll('.form');
const cardName = <HTMLInputElement>document.getElementById('card-name');
const forwardBtn = <HTMLButtonElement>document.getElementById('forward');
const textfield = document.getElementById('step-3-text') as HTMLInputElement;

if(form) {
    form.forEach(element => {
        const inputs = Array.from(element.querySelectorAll('input'));
        
        inputs.forEach(input => {
            if(input.value !== ''){
                input.classList.add('has-content');
                forwardBtn.removeAttribute('disabled');
            }
        });

        element.addEventListener('focusin', (event) => {
        
            const target = event.target as HTMLElement;
            target.classList.add('focus');
            if (target.value !== '') {
                forwardBtn.removeAttribute('disabled');
            }
            
        });
        element.addEventListener('focusout', (event) => {
            const target = event.target as HTMLFormElement;
            target.classList.remove('focus');
           
            if (target.value !== '') {
                target.classList.add('has-content');
                forwardBtn.removeAttribute('disabled');
                
            }
            else {
                target.classList.remove('has-content');
                forwardBtn.setAttribute('disabled','true');
            }
        })
    });
}

if(cardName) {
    cardName.addEventListener('keyup', (e) => {

        if(cardName.value != '') {
        
            forwardBtn.removeAttribute('disabled');
            
        }
        else {
            forwardBtn.setAttribute('disabled','true');
        }
    })
}

if(cardName) {

    if(cardName.value != '' || cardName.classList.contains('has-content')) {
        forwardBtn.removeAttribute('disabled');
    }
    else {
        forwardBtn.setAttribute('disabled','true');
    }
}


forwardBtn.addEventListener('click', () => {
    textfield!.value = "...\n\n\nLiebe Grüße,\n" + cardName.value;
})


// Postcard back form Validation
export default function formValidateCheck () {
    const requiredInput = document.querySelector('.backside')?.querySelectorAll("input[required]");
    const errorMessage = document.querySelector('.error-message') as HTMLElement;

    for (const input of requiredInput!) {
       
        if(input.value == '') {
            errorMessage.innerText = 'Bitte füllen Sie diese *Felder aus.';
            return 'notValid'
        }
        else {
            errorMessage.innerText = '';
        }
    }
}

const readOnlyInput = () => {
    const postcardInput = document.querySelector('.backside')?.querySelectorAll("input[required]");
    if(window.innerWidth < 992) {
        postcardInput?.forEach(input => {
            const inputField = input as HTMLInputElement;
            inputField.readOnly = true;
        })
    }
}
readOnlyInput();
window.addEventListener('resize',readOnlyInput);


// mobil postcard form -> validation and put values into the postcard input fields
const requiredInput = document.querySelector('#offcanvasBottom')?.querySelectorAll(".form-control");
const postcardInputs = document.querySelector('.backside')?.querySelectorAll(".form-control");
if(requiredInput) {
    requiredInput.forEach(input => {
        input.addEventListener('focusout', () => {
            const errorDiv = input.nextElementSibling as HTMLElement;
            if(input.value == '') {
                if(errorDiv) {
                    errorDiv.innerText = 'Bitte füllen Sie diese *Felder aus.';
                }
            }
        })
        input.addEventListener('keyup', () => {
            const errorDiv = input.nextElementSibling as HTMLElement;
            if(input.value !== '') {
                if(errorDiv) {
                    errorDiv.innerText = '';
                }
                for (let i = 0; i < postcardInputs!.length; i++) {
                    const postcardInput = postcardInputs![i];
                    console.log(input.value);
                    if(input.name == postcardInput.name && input.readOnly == false) {
                        postcardInput.value = input.value;
                    }
                } 
            }
        })
    })
}

const readyBtn = document.getElementById('mobil-form-submit');
var myOffcanvas = document.getElementById('offcanvasBottom');
const body = document.querySelector('body') as HTMLBodyElement;
var bsOffcanvas = new Offcanvas(myOffcanvas!)
readyBtn?.addEventListener('click', (e) => {
    const requiredInputs = document.querySelector('#offcanvasBottom')?.querySelectorAll(".form-field");

    for (let i = 0; i < requiredInputs.length; i++) {
        
        if(requiredInputs![i].value == '') {
            const errorDiv = requiredInputs![i].nextElementSibling as HTMLElement;
            if(errorDiv) {
                errorDiv.innerText = 'Bitte füllen Sie diese *Felder aus.';
            }
        }
        else {
            bsOffcanvas.hide();
            
        }
    }
})

myOffcanvas?.addEventListener('hidden.bs.offcanvas', () => {
    body.style.removeProperty('overflow');
	body.style.removeProperty('padding-right');
})


const resetInputValues = () => {
    const formControls = document.querySelectorAll('.form-field');
 
    formControls.forEach(formControl => {
        const input = formControl as HTMLInputElement;
        input.value = '';
        input.classList.remove('has-content');

    })
 }

resetInputValues();



document.querySelector("#step-4-form")?.addEventListener("submit", function (e) {

    e.preventDefault();
    const postcardData = {
        image: document.getElementById('step-4-form')?.querySelector('.card-front-overview img')?.getAttribute('src') as String,
        textfield: document.querySelector('#step-4-form .card-back-textbox textarea')?.value as String,
        name: document.querySelector('#step-4-form .card-back-name input')?.value as String,
        address: document.querySelector('#step-4-form .card-back-address input')?.value as String,
        houseNum: document.querySelector('#step-4-form .card-back-house input')?.value as String,
        postcode: document.querySelector('#step-4-form .card-back-postal input')?.value as String,
        city: document.querySelector('#step-4-form .card-back-city input')?.value as String,
        country: document.querySelector('#step-4-form .card-back-country input')?.value as String,

    }
    fetch("submit.php", { // fetch the file
        method: "POST", // POST method
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postcardData),
    }).then(function (oResult) { // when the response is returned
        console.log(oResult);
    }).catch (function (error) {
        console.log ("error: " + error);
     });

});

